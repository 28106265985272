<template>
  <div id="coupon-usage">


      <auth-page-card subtitle="Πληκτρολογήστε το κουπόνι σας και τον κωδικό σας για να δείτε το ιστορικό συναλλαγών."
      container-classes="col-xl-7">


        <b-overlay :show="api.loading" size="sm">

          <b-form v-if="!api.successData" @submit.prevent="findCoupon">
            <b-form-group label="Κωδ. Κουπονιού">
              <b-form-input v-model="coupon.code" @input="val => coupon.code = val.toUpperCase()" placeholder="Ο κωδικός κουπονιού σας"/>

            </b-form-group>

            <b-form-group label="Κωδ. πρόσβασης">
              <b-form-input v-model="coupon.access_token" type="password" placeholder="Ο κωδ. πρόσβασής σας"/>

            </b-form-group>

            <b-button variant="primary" block type="submit">Προβολή συναλλαγών</b-button>
          </b-form>

            <div v-else>

              <h4 class="text-center">Κωδικός κουπονιού: {{coupon.code}}</h4>
              <b-table :items="api.rows" :fields="fields" class="table table-hover" responsive :current-page="api.page">
                <template v-slot:cell(name)="row" class="text-center">
                  {{row.item.user.firstName + ' ' + row.item.user.lastName}}
                </template>
              </b-table>

              <pagination-row :api="api" @fetchData="getData"></pagination-row>

              <b-button variant="outline-primary" block type="submit" @click="api.successData = false"><span class="icon-refresh"/> Επιστροφή στην εισαγωγή κουπονιού</b-button>
            </div>
        </b-overlay>
      </auth-page-card>



  </div>

</template>
<style lang="scss">

</style>

<script>

import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import AuthPageCard from "@components/auth/AuthPageCard";
import PaginationRow from "@components/global/PaginationRow";
import moment from "moment";

export default{
  components: {PaginationRow, AuthPageCard},
  mixins: [PaginatedDataMixin],
  props:{
  },
  computed:{

    extraParams(){
      return {
        coupon: this.coupon.code,
        access_token: this.coupon.access_token
      }
    }
  },
  data(){

    return{
      fields: [
        {key: 'name', label: 'Ονοματεπώνυμο'},
        {key: 'amount', label: 'Ποσό', formatter: value => { return value ? value.toFixed(2) + '€' : '-'}},
        {key: 'type', label: 'Είδος', formatter: value => {
            if(value === 'RENEW') return '🔄 Ανανέωση (2η δόση)';
            if(value === 'PURCHASE') return '✅ Αγορά λογισμικού'
          }},
        {key: 'updatedAt', label: 'Ημερομηνία', formatter: value => {
            return moment(value).format('DD/MM/YYYY HH:mm')}
        },
      ],
      step: 0,
      coupon:{
        code: null,
        access_token: null
      }
    }
  },
  created(){
    this.api.loading = false; // remove loading state.
    this.api.url = "/general/coupon-code-usage";
  },
  methods:{
    findCoupon(){
      this.getData();
    }
  }
}
</script>